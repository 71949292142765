import React from "react";
import { Link } from "gatsby";

import imgL1Logo from "../../assets/image/png/easycourse_logo.png";
import imgL1LogoWhite from "../../assets/image/png/easycourse_logo_white.png";

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      {white ? (
        <img width="320px" src={imgL1LogoWhite} alt="" />
      ) : (
        <img width="200px" src={imgL1Logo} alt="" />
      )}
    </Link>
  );
};

export default Logo;
