import React from "react"
import styled from "styled-components"
import { color, space, typography, shadow, layout, border } from "styled-system"

const InputSolid = styled.textarea`
  font-size: 18px;
  font-weight: 300;
  resize: none;
  letter-spacing: -0.56px;
  display: block;
  padding: 1.275rem 1.75rem;
  background-clip: padding-box;
  border: ${({ theme }) => `1px solid ${theme.colors.border}`};
  border-radius: 10px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
  ${layout};
  ${border};
  &:focus,
  &:active,
  &.active {
    border-color: ${({ theme, focusColor }) => theme.colors[focusColor]};
    outline: 0;
    box-shadow: none;
  }
`

const Input = ({
  variant = "solid",
  type = "text",
  focusColor = "secondary",
  placeholder,
  css,
  ...rest
}) => {
  return (
    <InputSolid
      width="100%"
      type={type}
      color="text"
      bg="light"
      placeholder={placeholder}
      focusColor={focusColor}
      css={css}
      {...rest}
    />
  )
}

export default Input
